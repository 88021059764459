import React, { Component } from "react";
import { View, Text, Dimensions, Image, TouchableOpacity } from "react-native";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import VideoComp from "./Video";

let videos = [
	{
		id: 1,
		src: "https://firebasestorage.googleapis.com/v0/b/traq-e3acf.appspot.com/o/ElizabethArden_WhiteTea_ToningLotionCleanser_16x9_FINAL_220222.mp4?alt=media&token=cf7678f5-6a29-4e40-9149-66945da3d123",
		title: "Video 1",
	},
	{
		id: 2,
		src: "https://firebasestorage.googleapis.com/v0/b/traq-e3acf.appspot.com/o/ElizabethArden_WhiteTea_Micro-Gel_Cream_16x9_FINAL.mp4?alt=media&token=dbc998b0-cabf-4168-8f00-0195509e9774",
		title: "Video 2",
	},
	{
		id: 3,
		src: "https://firebasestorage.googleapis.com/v0/b/traq-e3acf.appspot.com/o/ElizabethArden_WhiteTea_FortifyingBi-PhaseOilSerum_16x9_FINAL.mp4?alt=media&token=d1abff93-6ba2-4d6c-b37b-9459b53caab2",
		title: "Video 3",
	},
	{
		id: 4,
		src: "https://firebasestorage.googleapis.com/v0/b/traq-e3acf.appspot.com/o/ElizabethArden_WhiteTea_BrighteningEyeGel_16x9_FINAL.mp4?alt=media&token=06f3de31-d908-4c9b-80c3-e3a8abed8983",
		title: "Video 4",
	},
];

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	async componentDidMount() {
		await window.addEventListener("resize", this.resize.bind(this));
		await this.resize();
	}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	render() {
		return (
			<Router>
				<Routes>
					{videos.map((d, i) => (
						<Route
							key={i}
							path={`/${d.id}`}
							element={<VideoComp video={d} />}
						/>
					))}

					<Route
						path={`/`}
						element={<VideoComp path={`/`} video={videos[0]} />}
					/>
					<Route path='*' element={<Navigate to='/' />} />
				</Routes>
			</Router>
		);
	}
}

export default index;
