import React, { useState, useEffect } from "react";
import { api_qrscanned } from "./Api";
import axios from "axios";

function UUIDGenerator() {
	const [uuid, setUUID] = useState("");

	useEffect(() => {
		const fetchData = async () => {
			try {
				// Check if UUID is present in localStorage
				const uuidFromStorage = localStorage.getItem("uuid");
				if (uuidFromStorage) {
					setUUID(uuidFromStorage);
				} else {
					// Generate a new UUID and save to localStorage
					const newUUID = generateUUID();
					await postToAPI(newUUID);
					localStorage.setItem("uuid", newUUID);
					setUUID(newUUID);
				}
			} catch (error) {
				console.error(error);
			}
		};

		fetchData();
	}, []);

	const generateUUID = () => {
		const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
			/[xy]/g,
			function (c) {
				const r = (Math.random() * 16) | 0,
					v = c == "x" ? r : (r & 0x3) | 0x8;
				return v.toString(16);
			},
		);
		return uuid;
	};

	const geoAppify = async () => {
		try {
			const { data } = await axios.get(`https://ipapi.co/json/`);

			const analyticsData = {
				...data,
				userAgent: window.navigator.userAgent,
				screenWidth: window.screen.width,
				screenHeight: window.screen.height,
			};

			console.log("TrackVisitorAndIP analyticsData", analyticsData);

			return analyticsData;
		} catch (error) {
			console.error(error);
			return null;
		}
	};

	const postToAPI = async (uuid) => {
		const ip = await geoAppify();

		console.log("geoAppify", ip);

		try {
			await axios.post(api_qrscanned, {
				allocation: "EA Press event Sleeve",
				uuid,
				...ip,
			});
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<div
			style={{
				display: "none",
			}}>
			{uuid}
		</div>
	);
}

export default UUIDGenerator;
