import React, { useState, useEffect } from "react";
import { View, Text, Image, ScrollView, TouchableOpacity } from "react-native";
import SubmitButton from "./Submit";

const products = [
	{
		id: 1,
		name: "White Tea EDP",
		image: require("./assets/wtedp.jpg"),
	},
	{
		id: 2,
		name: "WHITE TEA WILD ROSE EDT",
		image: require("./assets/wrose.jpg"),
	},
	{
		id: 3,
		name: "WHITE TEA EDT",
		image: require("./assets/wt.jpg"),
	},
	{
		id: 4,
		name: "White Tea Wild MANDARIN BLOSSOM EDT",
		image: require("./assets/wtblossom.jpg"),
	},
	{
		id: 5,
		name: "White Tea Gingerlily EDT",
		image: require("./assets/wtginger.jpg"),
	},
];

function ProductList() {
	const [selectedProduct, setSelectedProduct] = useState(null);

	useEffect(() => {
		// Check if selectedProduct is present in localStorage
		const productFromStorage = localStorage.getItem("selectedProduct");
		if (productFromStorage) {
			const parsedProduct = JSON.parse(productFromStorage);
			setSelectedProduct(parsedProduct);
		}
	}, []);

	// Handle product selection
	const handleProductSelection = (product) => {
		setSelectedProduct(product);
		// Save selected product to localStorage
		localStorage.setItem("selectedProduct", JSON.stringify(product));
	};

	return (
		<View
			style={{
				paddingTop: 50,
				justifyContent: "center",
				alignItems: "center",
				// maxWidth: 380,
				width: "100%",

				backgroundColor: "#FFF",
				height: window.innerHeight,
			}}>
			<Text
				style={{
					color: "#434547",
					fontSize: 25,
					display: "inline",
					fontWeight: "600",
					fontFamily: "serif",
					padding: 10,
				}}>
				FIND
				<Text
					style={{
						fontStyle: "italic",
						display: "inline",
						fontWeight: "500",
					}}>
					{" "}
					your{" "}
				</Text>
				SCENT
			</Text>
			<View
				style={{
					paddingLeft: 10,
					paddingRight: 10,
				}}>
				<p
					style={{
						marginBottom: 10,
						fontWeight: "400",
						fontStyle: "italic",
						opacity: 0.6,
						fontSize: 13,
					}}>
					Select one of the White Tea Products that best describes
					your scent
				</p>
			</View>
			<View
				style={{
					width: "100%",
					flexDirection: "row",
					flexWrap: "wrap",
					justifyContent: "space-evenly",
				}}>
				{products.map((product) => (
					<TouchableOpacity
						onPress={() => handleProductSelection(product)}>
						<View
							key={product.id}
							style={{
								width: window.innerWidth / 3.6,

								borderRadius: 5,
								marginBottom: 20,
								padding: 10,
								borderWidth: 2,
								height: 195,
								borderColor: "#ccc",
								overflow: "hidden",
								justifyContent: "center",
								alignItems: "center",
								...(selectedProduct &&
								product.id === selectedProduct.id
									? {
											borderColor: "#1E90FF",
											borderWidth: 2,
									  }
									: {}),
							}}>
							<Image
								source={product.image}
								style={{ width: 100, height: 100 }}
							/>
							<Text
								style={{
									textAlign: "center",
									fontSize: 14,
									padding: 5,
									fontWeight: "500",
									color: "#797979",
									...(selectedProduct &&
									product.id === selectedProduct.id
										? {
												color: "#1E90FF",
										  }
										: {}),
								}}>
								{product.name}
							</Text>
						</View>
					</TouchableOpacity>
				))}
				{/* </ScrollView> */}
			</View>

			<View
				style={{
					width: "100%",
					padding: 10,
				}}>
				{selectedProduct && (
					<View
						style={{
							width: "100%",
							padding: 10,
						}}>
						<SubmitButton />
					</View>
				)}
			</View>
		</View>
	);
}

export default ProductList;
