import React, { useRef } from "react";
import { ScrollView, View, Dimensions } from "react-native";

function SnapScrollView({ children }) {
	const scrollViewRef = useRef();
	const windowHeight = Dimensions.get("window").height;

	const handleScroll = (event) => {
		const offsetY = event.nativeEvent.contentOffset.y;
		const snappedOffsetY =
			Math.round(offsetY / windowHeight) * windowHeight;
		scrollViewRef.current.scrollTo({ y: snappedOffsetY });
	};

	return (
		<ScrollView
			ref={scrollViewRef}
			// onScroll={handleScroll}
			// snapToInterval={windowHeight}
			snapToAlignment={"start"}
			snapToOffsets={window.innerHeight}
			decelerationRate='fast'
			pagingEnabled
			contentContainerStyle={{
				justifyContent: "center",
				alignItems: "center",
			}}>
			{children}
		</ScrollView>
	);
}

export default SnapScrollView;
