import React, { Component } from "react";
import { View, Text, Image, ScrollView } from "react-native";
import VideoPlayer from "./VideoPlayer";
import DownloadShare from "./DownloadShare";

import OpenLinkInNewTab from "./OpenLinkInNewTab";

import LogoBanner from "./LogoBanner";
import EventMessage from "./EventMessage";
import SanctuaryForm from "./SanctuaryForm";
import EscapeForm from "./EscapeForm";
import SourceForm from "./SourceForm";
import TimeForm from "./TimeForm";
import MomentForm from "./MomentForm";
import ProductList from "./ProductList";
import VideoDownload from "./DownloadShare";
import UUIDGenerator from "./UUID";

import SnapScrollView from "./SnapScrollView";

export default class Video extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<View
				style={{
					// flex: 1,
					// backgroundColor: "#FFFEE9",
					width: window.innerWidth,
					height: window.innerHeight,
					overflow: "hidden",
					justifyContent: "center",
					alignItems: "center",
				}}>
				<UUIDGenerator />

				<SnapScrollView>
					<View
						style={{
							height: window.innerHeight,
							justifyContent: "center",
							alignItems: "center",
							padding: 10,
						}}>
						{/* <LogoBanner /> */}
						<VideoPlayer {...this.props} />
						{/* <View
							style={{
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<VideoDownload />
							<OpenLinkInNewTab />
						</View> */}
					</View>

					{/* <EventMessage />

					<SanctuaryForm />
					<EscapeForm />
					<SourceForm />
					<TimeForm />
					<MomentForm />
					<ProductList /> */}
				</SnapScrollView>
			</View>
		);
	}
}
