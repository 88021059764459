import React, { useState, useEffect, useRef } from "react";

const VideoComp = ({ video }) => {
	const videoPlayerRef = useRef(null);

	useEffect(() => {
		if (videoPlayerRef.current) {
			videoPlayerRef.current.play();
		}
	}, [video]);

	return (
		<div
			style={{
				// flex: 1,
				// width: window.innerWidth,
				// height: window.innerHeight,
				justifyContent: "center",
				alignItems: "center",
				// overflow: "hidden",
			}}>
			<video
				width={window.innerWidth}
				height={window.innerHeight / 2}
				controls
				ref={videoPlayerRef}
				src={video ? video.src : null}
				autoPlay={true}
				muted
				style={{
					objectFit: "cover",
				}}
			/>
		</div>
	);
};

export default VideoComp;
